<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="code">Scrap</vs-th>
                <vs-th sort-key="name">Warehouse</vs-th>
                <vs-th sort-key="name">Document Date</vs-th>
                <!-- <vs-th sort-key="name">Posting Date</vs-th> -->
                <!-- <vs-th sort-key="name">Supplier</vs-th> -->
                <vs-th sort-key="name">Charge To</vs-th>
                <vs-th sort-key="name"># of SKU</vs-th>
                <vs-th sort-key="name">TOTAL Qty [UOM]</vs-th>
                <vs-th sort-key="name">Inventory Value</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex" v-if="checkStatus == 'WaitingApproval'">
                            <vx-tooltip text="View">
                                <vs-button class="mr-1" size="small" color="grey" icon-pack="feather" icon="icon-eye"
                                    @click="handleView(tr.id)" />
                            </vx-tooltip>
                            <vx-tooltip text="Inquiry">
                                <vs-button class="mr-1" size="small" color="success" icon-pack="feather" icon="icon-rotate-ccw"
                                    @click="handleInquiry(tr.id)" />
                            </vx-tooltip>
                            <vx-tooltip text="Reject">
                                <vs-button class="mr-1" size="small" color="danger" icon-pack="feather" icon="icon-x"
                                    @click="handleReject(tr.id)" />
                            </vx-tooltip>
                            <vx-tooltip text="Approve">
                                <vs-button class="mr-1" size="small" color="success" icon-pack="feather" icon="icon-check"
                                    @click="handleApprove(tr.id)" />
                            </vx-tooltip>
                        </div>
                        <div class="vx-input-group flex" v-else>
                            <vx-tooltip text="View">
                                <vs-button class="mr-1" size="small" color="grey" icon-pack="feather" icon="icon-eye"
                                    @click="handleView(tr.id)" />
                            </vx-tooltip>
                        </div>
                    </vs-td>
                    <vs-td :data="data[indextr].code">
                        Code : {{ data[indextr].code }} <br>
                        Type : {{ data[indextr].type }}
                    </vs-td>
                    <vs-td :data="data[indextr].warehouse_code">
                        {{ data[indextr].warehouse_code }} - {{ data[indextr].warehouse_name }}
                    </vs-td>
                    <vs-td :data="data[indextr].date">
                        {{ formatDate(data[indextr].date) }}
                    </vs-td>
                    <!-- <vs-td :data="data[indextr].posting_date">
                        {{ formatDate(data[indextr].posting_date) }}
                    </vs-td> -->
                    <!-- <vs-td :data="data[indextr].supplier_code">
                        {{ data[indextr].supplier_code }} - {{ data[indextr].supplier_name }}
                    </vs-td> -->
                    <vs-td :data="data[indextr].charge">
                        {{ data[indextr].charge }}
                    </vs-td>
                    <vs-td :data="data[indextr].total_line">
                        <div v-html="data[indextr].total_line"></div>
                    </vs-td>
                    <vs-td :data="data[indextr].quantity">
                        {{ data[indextr].quantity }}
                    </vs-td>
                    <vs-td :data="priceFormat(data[indextr].total_map)">
                        {{ priceFormat(data[indextr].total_map) }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        warehouseId: {
            type: Array,
        },
        supplierId: {
            type: Array,
        },
        status: {
            type: String,
        },
    },
    data() {
        return {
            confirmId: null,
            table: this.tableDefaultState(),
            activeTab: 0,
            activeStatus: 1,
            isApproval: 0,
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        getData() {

            if(this.status == "WaitingApproval"){
                this.activeStatus = 0
                this.isApproval = 1
            }else if(this.status == "ApprovedApproval"){
                this.activeStatus = 1
                this.isApproval = 2
            } else if(this.status == "InquiredApproval"){
                this.activeStatus = 3
            } else if(this.status == "RejectedApproval"){
                this.activeStatus = 2
            }


            this.$vs.loading();
            this.$http
            .get("/api/wms/v1/scrap/approval-table", {
                params: {
                    length: this.table.length,
                    page: this.table.page,
                    search: this.table.search,
                    order: this.table.order,
                    sort: this.table.sort,
                    warehouse_id: this.warehouseId,
                    supplier_id: this.supplierId,
                    status: this.activeStatus,
                    is_approval: this.isApproval,
                },
            })
            .then((resp) => {
                if (resp.code == 200) {
                    this.table.total = resp.data.total_record;
                    this.table.totalPage = resp.data.total_page;
                    this.table.totalSearch = resp.data.total_record_search;
                    this.table.length = resp.data.total_record_per_page;
                    this.table.data = resp.data.records;
                    this.setStartEnd();
                    this.$vs.loading.close();
                } else {
                    // this.$vs.loading.close();
                }
            });

            this.$vs.loading.close();
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleApprove(id) {
            this.$vs.dialog({
                type: "confirm",
                color: "success",
                title: `Approve`,
                text: "Approve this data",
                accept: () => this.acceptApprove(id),
            });
        },
        acceptApprove(id) {
            this.$vs.loading();
            this.$http
                .put("/api/wms/v1/scrap/approve/" + id)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "The data was successfully approved",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                    this.handleClose();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });


        },
        handleReject(id) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Reject`,
                text: "Reject this data",
                accept: () => this.acceptReject(id),
            });
        },
        acceptReject(id) {
            this.$vs.loading();
            this.$http
                .put("/api/wms/v1/scrap/reject/" + id)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "The data was successfully rejected",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                    this.handleClose();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });

        },
        handleInquiry(id) {
            this.$vs.dialog({
                type: "confirm",
                color: "primary",
                title: `Inquiry`,
                text: "Inquiry this data",
                accept: () => this.acceptInquiry(id),
            });
        },
        acceptInquiry(id) {
            this.$vs.loading();
            this.$http
                .put("/api/wms/v1/scrap/inquiry/" + id)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "The data was successfully inquiry",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                    this.handleClose();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });

        },
        handleView(id) {
            if(this.status == "WaitingApproval"){
                this.activeTab = 0
            }else if(this.status == "ApprovedApproval"){
                this.activeTab = 1
            } else if(this.status == "InquiredApproval"){
                this.activeTab = 2
            }  else if(this.status == "RejectedApproval"){
                this.activeTab = 3
            }
            this.$router.push({
                name: "stock-scrap-view",
                query: { id: id, tab: this.activeTab, is_approval: 1 },
            });
        },
    },
    mounted() { },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('DD MMM YYYY');
            };
        },
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        checkStatus(){
            return this.status;
        },
    },
};
</script>
